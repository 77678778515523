<template>
  <div>
    <component ref="model" :is="getComonent(config.modelName||'Model-1')" @ready="modelReady" @Hide="onhide">
      <div style="position:relative">
        <div class="text-shadow" style="position:absolute;top:-5px;right:0;padding:5px;cursor:pointer;" @click="$refs.model.hideModal()">
          <i class="fa fa-times"></i>
        </div>
        <div>
          <h4 style=" color: white; margin-right: 25px; font-size: 16px; font-weight: bold; margin-bottom: 12px;">{{intro.title}}</h4>
        </div>
        <div v-if="intro.introImage&&intro.introImage.isImage">
          <img :src="intro.introImage.url.replace('/0/0/0/0/', '/800/0/0/0/')" />
        </div>
        <div v-if="intro.text">
          <article class="introText" v-html="intro.text"></article>
        </div>
      </div>
    </component>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        modelShow: null,
        modelHide: null,
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {},
      getComonent: {},
    },
    computed: {
      intro() {
        return this.publicData.intro || {}
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
          next: {
            name: 'nextView',
            target: 'inViewData'
          },
        },
        funcs: {
          show: this.show,
        }
      })
    },
    destroyed() {
    },
    methods: {
      modelReady({ funcs }) {
        this.modelShow = funcs.show
        this.modelHide = funcs.hide
        this.modelShow()
      },
      show() {
        if (this.modelShow) {
          this.modelShow()
        } else {
          alert('模态框还没有准备好')
        }
      },
      onhide() {
        this.$emit('next')
      }
    },
  }
</script>
<style scoped>
  .introText {
    color: white;
    text-align: justify;
  }
</style>
